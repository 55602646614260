import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import "../Login.css";
import portalLogo from "../../../assets/farmkar_assets/Png/logo-black.png";
import { handlePostRequest } from "../../../services/PostTemplate";

const Otp = () => {
    const [saveBtnLoading, setSaveBtnLoading] = useState(false);
    const [timeLeft, setTimeLeft] = useState(300); // 5 minutes = 300 seconds
    const dispatch = useDispatch();
    const history = useHistory();

    const location = useLocation();
    const phoneNo = location.state?.phoneNo;

    const validationSchema = Yup.object().shape({
        otp: Yup.mixed().required("This field is required."),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            otp: "",
        },

        onSubmit: async (data) => {
            setSaveBtnLoading(true);

            const obj = {
                otp: formik.values.otp,
                phone: phoneNo,
            };

            const response = await dispatch(handlePostRequest(obj, "/api/v1/users/verifyOtp", false, true));
            if (response?.status === 200) {
                history.push({
                    pathname: `/resetpassword`,
                    state: { phoneNo: phoneNo },
                });
            } else {
                setSaveBtnLoading(false);
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {
        if (timeLeft > 0) {
            const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
            return () => clearTimeout(timerId);
        } else {
            // Redirect to the Forgot Password screen when time runs out
            history.push("/forgotpassword");
        }
    }, [timeLeft, history]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
    };

    return (
        <>
            <div className="login-page-container splash-screen">
                <div className="left-section ml-6">
                    <div className="login2-box">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="login-headings">
                                <h2 style={{ fontWeight: "700" }}> OTP </h2>
                                <p className="mb-4" style={{ color: "gray", fontWeight: "600", fontSize: "18px" }}>
                                    Please enter your valid OTP for password reset and recovery.
                                </p>
                            </div>
                            <div className="mt-2">
                                <label htmlFor="phone" className="text-xl font-medium mb-2">
                                    OTP
                                </label>
                                <InputText placeholder="Enter your OTP" keyfilter={/^[0-9.!@#$%^&*]$/} maxLength={4} id="otp" name="otp" type="text" value={formik.values.otp} onChange={formik.handleChange} className="w-full login-input mt-3 mb-2" />
                                {getFormErrorMessage("otp")}
                                <div className="timer text-gray-600 text-center mt-3">
                                    <p>Time Remaining: {formatTime(timeLeft)}</p>
                                </div>
                                <div className="col-12 d-flex flex-row text-center mt-5">
                                    <Button className="Login-Button w-full" label="Verify OTP" loading={saveBtnLoading} type="submit" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="right-section ">
                    <img src={portalLogo} className="logo-portal mr-6" />
                </div>
            </div>
        </>
    );
};

export default Otp;
