import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingOverlay from "react-loading-overlay";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";
import { useHistory } from "react-router-dom";
import { FileUpload } from "primereact/fileupload";
import { baseURL } from "../../Config";
import { handlePatchRequest } from "../../services/PatchTemplate";
import { RadioButton } from "primereact/radiobutton";

const UpdateEquipment = ({ onHide, getEquipmentList, addEditUser, userRowData, viewDisable }) => {
    
    console.log('userRowData', userRowData)
    const [saveBtnLoading, setSaveBtnLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
   

    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({

    });

    const formik = useFormik({
        // validationSchema: validationSchema,
        initialValues: {
            isActive: "",
        },

        onSubmit: async (data) => {
            if (addEditUser === true) {
                let obj = {
                    equipmentId: userRowData?.equipmentId,
                    isActive: formik.values.isActive,
                };

                setSaveBtnLoading(true);
                const response = await dispatch(handlePatchRequest(obj, `/api/v1/equipment/update`, false, true));
                if (response?.status === 200) {
                    getEquipmentList();
                    setSaveBtnLoading(false);
                    onHide();
                } else {
                    setSaveBtnLoading(false);
                }
            } else {
                // setSaveBtnLoading(true);
                // let obj = {
                //     equipmentCategory: formik.values.equipmentCategory,
                //     fuelType: formik.values.fuelType,
                //     englishName: formik.values.Name,
                //     model: formik.values.model,
                //     images: [selectedFiles[0]?.base64],
                // };

                // const response = await dispatch(handlePostRequest(obj, "/api/v1/equipment/create", true, true));
                // if (response) {
                //     getEquipmentList();
                //     setSaveBtnLoading(false);
                //     onHide();
                // }
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    //Get Equipment By Id
    const getEquipmentById = async () => {
        setIsActive(true);

        formik.setFieldValue("isActive", userRowData?.isActive == 1 ? true : false);
        setIsActive(false);
    };

    useEffect(() => {
        if (userRowData !== undefined && userRowData !== null && addEditUser === true) {
            getEquipmentById();
        }
    }, [userRowData, addEditUser]);


    //Disable Form while Viewing
    const formStyle = {
        pointerEvents: viewDisable ? "none" : "auto",
        opacity: viewDisable ? 0.7 : 1,
    };

    


    return (
        <>
            <LoadingOverlay
                active={isActive}
                spinner
                // text="Loading your content..."
                styles={{
                    overlay: (base) => ({
                        ...base,
                        color: "gray",
                        background: "none",
                        width: "107.9%",
                        height: "125%",
                        top: "-27px",
                        left: "-35px",
                    }),
                }}
            >
                <form onSubmit={formik.handleSubmit} style={formStyle}>
                    <div className="p-fluid formgrid grid pl-2 pr-2">
                        <div className="col-12 fields-display justifsy-content-evenly">
                            <div className="col-12 flex flex-column md:col-6">
                                <div className="flex flex-row">
                                    <label> Status </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <Dropdown placeholder="--Select--" options={[{ name: 'Active', value: true }, { name: 'Inactive', value: false },]} optionLabel="name" optionValue="value" className="p-inputtext-sm mt-2" id="isActive" name="isActive" value={formik.values.isActive} onChange={formik.handleChange} />
                                {getFormErrorMessage("isActive")}
                            </div>
                        </div>

                        {!viewDisable ? (
                            <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                                <Button className="Cancel-Button mr-1 mb-2" label="Cancel" type="button" onClick={onHide} />
                                <Button className="Save-Button ml-1 mb-2" label={addEditUser ? "Update" : "Add"} loading={saveBtnLoading} type="submit" />
                            </div>
                        ) : null}
                    </div>
                </form>
            </LoadingOverlay>
        </>
    );
};

export default UpdateEquipment;
