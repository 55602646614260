import React, { useState, useEffect, useContext } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import AddEditCustomer from "./AddEditCustomer";
import { FilterMatchMode } from "primereact/api";
import LoadingOverlay from "react-loading-overlay";
import { Triangle } from "react-loader-spinner";
import { InputText } from "primereact/inputtext";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { Divider } from "primereact/divider";
import { handleDeleteRequest } from "../../services/DeleteTemplate";
import { confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import deleteIcon from "../../assets/farmkar_assets/Png/delete.png";
import editIcon from "../../assets/farmkar_assets/Png/edit.png";
import viewIcon from "../../assets/farmkar_assets/Png/view.png";
import CustomDeleteDialog from "../../components/CustomDeleteDialog";
import { baseURL } from "../../Config";
import { Image } from "primereact/image";
import { Tag } from "primereact/tag";
import { Calendar } from "primereact/calendar";
import * as XLSX from "xlsx";
import moment from "moment";

const CustomerManagement = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [isActive, setIsActive] = useState(false);
    const [userRowData, setUserRowData] = useState();
    const [addEditUser, setAddEditUser] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [viewDisable, setViewDisable] = useState(false);
    const [referral, setReferral] = useState(false);
    const [date, setDate] = useState("");
    const [referralText, setReferralText] = useState("");
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="">
                {/* <Button className="view-icon" onClick={() => handleViewUser(rowData)}>
                    <img src={viewIcon} alt="View" />
                </Button> */}
                <Button className="edit-icon ml-2" onClick={() => handleEditUsers(rowData)}>
                    <img src={editIcon} alt="Edit" />
                </Button>
                <Button className="del-icon" onClick={() => confirm(rowData)}>
                    <img src={deleteIcon} alt="Delete" />
                </Button>
            </div>
        );
    };

    const confirm = (rowData) => {
        const acceptAction = () => handleDeleteUser(rowData);
        confirmDialog({
            message: <CustomDeleteDialog message="Are you sure you want to delete this user?" acceptAction={acceptAction} />,
            header: "Confirmation",
            accept: acceptAction,
            reject: null,
            draggable: false,
            resizable: false,
        });
    };

    const handleDeleteUser = async (rowData) => {
        setIsActive(true);

        const response = await dispatch(handleDeleteRequest(`/api/v1/farmer/delete/${rowData?.id}`, true, true));
        if (response) {
            await getCustomersList();
        }

        setIsActive(false);
    };

    const handleEditUsers = (rowData) => {
        setDisplayDialog(true);
        setAddEditUser(true);
        setUserRowData(rowData);
    };

    const handleViewUser = (rowData) => {
        setDisplayDialog(true);
        setAddEditUser(true);
        setViewDisable(true);
        setUserRowData(rowData);
    };

    const onHide = (name) => {
        setDisplayDialog(false);
        setAddEditUser(false);
        setViewDisable(false);
    };

    const handleDialog = () => {
        setDisplayDialog(true);
    };

    const handleReferral = () => {
        if (referral) {
            setReferral(false)
        }
        else {
            setReferral(true)
        }
    }
    console.log('referralText', referralText)
    console.log('date', date)

    const handleSearch = async () => {
        setIsActive(true);

        const response = await dispatch(handleGetRequest(`/api/v1/farmer/stats?referral=${referralText}&createdDate=${date ? moment(date).format('DD-MM-YYYY') : null}`, true));
        console.log("response", response);
        if (response?.status == 200) {
            setAllUsers(response?.data);
            setIsActive(false);
        } else {
            setIsActive(false);
        }
    }

    //Get All Customers
    const getCustomersList = async () => {
        setIsActive(true);

        const response = await dispatch(handleGetRequest("/api/v1/farmer/getAll", true));
        if (response) {
            setAllUsers(response?.data);
        }
        setIsActive(false);
    };

    useEffect(() => {
        getCustomersList();
    }, []);


    const exportExcel = () => {

        // Map data to export fields
        const dataToExport = allUsers?.map(client => ({
            Name: client?.name || "N/A",
            UrduName: client?.urduName || "N/A",
            Phone: client?.referral || "N/A",
            Contact: client?.phone || "N/A",
            CNIC: client?.cnic || "N/A",
            CreatedAt: moment(client?.createdDate).format('DD-MM-YYYY') || "N/A",
            Status: client?.isActive ? 'Active' : 'Inactive' || "N/A",
        }));

        // Create a new worksheet and workbook
        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "All Customers List");

        // Export the file
        XLSX.writeFile(workbook, "AllClients.xlsx");
    };

    const imageTemplate = (rowData) => {
        return (
            <React.Fragment>
                {referral ?
                    <Image src={`${baseURL}/${rowData?.farmerProfileImg}`} zoomSrc={`${baseURL}/${rowData?.farmerProfileImg}`} alt="Image" preview />
                    :
                    <Image src={`${baseURL}/${rowData?.profileImage}`} zoomSrc={`${baseURL}/${rowData?.profileImage}`} alt="Image" preview />
                }
            </React.Fragment>
        );
    };

    const Header = () => {
        return (
            <>
                <h3 className="pr-2 pl-2 text-center" style={{ fontWeight: "600" }}>
                    {addEditUser && !viewDisable ? "Edit Customer" : viewDisable && addEditUser ? "View Details" : "Add Customer"}
                </h3>
                <div className="mt-2">
                    <hr />
                </div>
            </>
        );
    };

    console.log('allUsers',allUsers)

    return (
        <>
            <Dialog draggable={false} resizable={false} header={Header} visible={displayDialog} style={{ width: "64vw" }} onHide={onHide}>
                <AddEditCustomer
                    onHide={onHide}
                    getCustomersList={getCustomersList}
                    addEditUser={addEditUser}
                    userRowData={userRowData}
                    viewDisable={viewDisable}
                />
            </Dialog>


            <div className="card dark-bg">

                <LoadingOverlay
                    active={isActive}
                    spinner={<Triangle height="120" width="120" color="#798403" ariaLabel="triangle-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: "rgb(38 41 51 / 78%)",
                        }),
                    }}
                >
                    <DataTable
                        header={
                            <>
                                <div className="flex justify-content-between custom-alignment" style={{ display: "flex", alignItems: "center", bordserBottom: "1px solid #ccc" }}>
                                    <div>
                                        <span className="search-container">
                                            <i className="pi pi-search search-icon" />
                                            <InputText placeholder="Search" value={globalFilterValue} onChange={onGlobalFilterChange} className="search-input mb-3" />
                                        </span>
                                    </div>


                                    <div className="">
                                        <Button
                                            label="Search via Referral"
                                            className="Add__New-Button mb-2 mr-2"
                                            icon={referral ? "pi pi-arrow-up" : "pi pi-arrow-down"}
                                            onClick={() => {
                                                handleReferral()
                                            }}
                                        />
                                       
                                        <Button
                                            label="Add New"
                                            className="Add__New-Button mb-2"
                                            icon="pi pi-plus"
                                            onClick={() => {
                                                handleDialog();
                                            }}
                                        />
                                    </div>
                                </div>
                                {referral ?
                                    <div className="flex justify-content-between custom-alignment" style={{ display: "flex", alignItems: "center", borderBottom: "1px solid #ccc" }}>
                                        <div>
                                        <Button
                                            disabled={allUsers  === undefined}
                                            label="Export Excel"
                                            className="Add__New-Button mb-2 mr-2"
                                            icon="pi pi-file-excel"
                                            onClick={() => {
                                                exportExcel();
                                            }}
                                        />
                                        </div>
                                        <div id="date" className="">
                                            <span className="search-constainer">
                                                <InputText
                                                    placeholder="Search via referral"
                                                    value={referralText}
                                                    onChange={(e) => setReferralText(e.target.value)}
                                                    className="search-inpsut mb-3 mr-2"
                                                />
                                            </span>
                                            <Calendar
                                                placeholder="--Select Date--"
                                                id="buttondisplay"
                                                className="mb-2 mr-2"
                                                value={date}
                                                onChange={(e) => setDate(e.target.value)} // PrimeReact Calendar uses `e.value` for the selected date
                                                showIcon
                                                maxDate={new Date()} // Disable future dates
                                            />
                                            <Button
                                                label="Search"
                                                className="Add__New-Button mb-3"
                                                icon="pi pi-search"
                                                onClick={() => {
                                                    handleSearch();
                                                }}
                                            />
                                        </div>
                                    </div>
                                    :
                                    null}
                            </>
                        }
                        responsive={true}
                        filters={filters}
                        globalFilterFields={["name", "cnic", "urduName", "phone", "isActive", "cnic", "createdDate", "referral"]}
                        responsiveLayout="scroll"
                        stripedRows
                        paginator
                        rows={20}
                        value={allUsers}
                        emptyMessage="No records found"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                    >
                        <Column body={imageTemplate} header="Profile Image"></Column>
                        <Column field="name" header="Name"></Column>
                        <Column field="urduName" header="Urdu Name"></Column>
                        <Column field='referral' body={(rowData) => rowData?.referral || 'N/A'} header="Referral"></Column>
                        <Column field="phone" header="Contact No."></Column>
                        <Column field="cnic" header="CNIC"></Column>
                        <Column field='createdDate' body={(rowData) => moment(rowData?.createdDate).format('DD-MM-YYYY') || 'N/A'} header="Created At"></Column>
                        <Column body={(rowData) => <Tag style={{ fontSize: "11px", padding: "3px 6px" }} value={rowData?.isActive ? "Active" : "Inactive"} severity={rowData?.isActive ? "success" : "danger"} />} header="Status" />
                        <Column header="Action" body={actionTemplate} />
                    </DataTable>
                </LoadingOverlay>
            </div>
        </>
    );
};

export default CustomerManagement;
