import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingOverlay from "react-loading-overlay";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { FileUpload } from "primereact/fileupload";
import { baseURL } from "../../Config";
import { handlePatchRequest } from "../../services/PatchTemplate";
import { Dropdown } from "primereact/dropdown";

const AddEditCustomer = ({ onHide, getCustomersList, addEditUser, userRowData, viewDisable }) => {

    const [saveBtnLoading, setSaveBtnLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [editableImage, setEditableImage] = useState('');
    const history = useHistory();
    const dispatch = useDispatch();

    const handlePaste = (e) => {
        e.preventDefault(); // Prevents pasting
        alert("Pasting is not allowed in this field.");
    };

    const isSequential = (value) => {
        // Skip the first two digits (03)
        const digits = value.slice(2).split("").map(Number);

        // Check if all digits are the same (e.g., "3333333333")
        const allSame = digits.every((digit) => digit === digits[0]);
        if (allSame) return true;

        // Check for strictly increasing sequence (e.g., "123456789")
        const isIncreasing = digits.every((digit, index) => {
            if (index === 0) return true; // Skip the first digit
            return digit === digits[index - 1] + 1;
        });
        if (isIncreasing) return true;

        // Check for strictly decreasing sequence (e.g., "987654321")
        const isDecreasing = digits.every((digit, index) => {
            if (index === 0) return true; // Skip the first digit
            return digit === digits[index - 1] - 1;
        });
        if (isDecreasing) return true;

        // Check for four consecutive identical digits
        for (let i = 0; i < digits.length - 3; i++) {
            if (digits[i] === digits[i + 1] && digits[i] === digits[i + 2] && digits[i] === digits[i + 3]) {
                return true; // Return true if four consecutive identical digits are found
            }
        }

        return false;
    };

    const validationSchema = Yup.object().shape({
        englishName: Yup.string()
            .trim() // Remove leading and trailing spaces
            .required("This field is required.") // Field must not be empty
            .matches(/^(?!\s*$).+/, "This field cannot be empty or just spaces"), // Prevents empty input or just spaces
        urduName: Yup.string()
            .trim() // Remove leading and trailing spaces
            .required("This field is required.") // Field must not be empty
            .matches(/^[\u0600-\u06FF\s]+$/, "Please enter a valid Urdu name using only Urdu characters.")
            .matches(/^(?!\s*$).+/, "This field cannot be empty or just spaces"),
        email: Yup.string()
            .email("Invalid email address") // Default email format validation
            .required("Email is required") // Email is required
            .max(30, "Email should not exceed 30 characters") // Max length validation
            .matches(
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[cC][oO][mM]$/, // Ensures email ends with .com
                "Email must end with .com" // Custom error message for .com requirement
            ),
        phone: Yup.string()
            .matches(/^03\d{9}$/, "Phone number must start with 03 and be 11 digits") // Matches valid phone format
            .test("is-not-sequential", "Invalid phone number (sequential numbers are not allowed)", (value) => {
                if (!value) return false; // Field is empty
                return !isSequential(value); // Check sequential validation
            })
            .required("This field is required."),

        cnic: Yup.string()
            .matches(/^\d{13}$/, "CNIC must be exactly 13 digits") // Must be 13 digits
            .test("is-not-sequential", "Invalid CNIC (sequential numbers are not allowed)", (value) => {
                if (!value) return false; // Field is empty
                return !isSequential(value); // Check for sequential numbers
            })
            .required("CNIC is required"),

        // pin: Yup.mixed().required("This field is required."),
        status: addEditUser ? Yup.mixed().required("This field is required.") : null,
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            englishName: "",
            urduName: "",
            email: "",
            phone: "",
            cnic: "",
            // pin: "",
            status: "",
        },

        onSubmit: async (data) => {
            if (addEditUser === true) {
                let obj = {
                    id: userRowData?.id,
                    Name: formik.values.englishName,
                    urduName: formik.values.urduName,
                    email: formik.values.email,
                    profileImage: selectedFiles[0]?.base64,
                    phone: formik.values.phone,
                    cnic: formik.values.cnic,
                    // pin: formik.values.pin,
                    isActive: formik.values.status,
                };

                setSaveBtnLoading(true);
                const response = await dispatch(handlePatchRequest(obj, `/api/v1/farmer/update`, false, true));
                if (response?.status === 200) {
                    getCustomersList();
                    setSaveBtnLoading(false);
                    onHide();
                } else if (response?.status === 403) {
                    window.localStorage.clear();
                    history.push("/");
                    toast.info("Please Login again");
                }
                getCustomersList();
                setSaveBtnLoading(false);
            } else {
                setSaveBtnLoading(true);

                let obj = {
                    name: formik.values.englishName,
                    urduName: formik.values.urduName,
                    profileImage: selectedFiles[0]?.base64,
                    email: formik.values.email,
                    phone: formik.values.phone,
                    cnic: formik.values.cnic,
                    // pin: formik.values.pin,
                    // referral: '',
                    // fcmToken: '',
                };

                const response = await dispatch(handlePostRequest(obj, "/api/v1/farmer/signUp", true, true));
                console.log("response", response);
                if (response?.status === 200) {
                    getCustomersList();
                    setSaveBtnLoading(false);
                    onHide();
                } else {
                    setSaveBtnLoading(false);
                }
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    //Get User By Id
    const getCustomerById = async () => {
        console.log("userRowData", userRowData);
        setIsActive(true);

        formik.setFieldValue("englishName", userRowData?.name);
        formik.setFieldValue("urduName", userRowData?.urduName);
        formik.setFieldValue("phone", userRowData?.phone);
        formik.setFieldValue("email", userRowData?.email);
        formik.setFieldValue("pin", userRowData?.pin);
        formik.setFieldValue("cnic", userRowData?.cnic);
        formik.setFieldValue("status", userRowData?.isActive);
        setEditableImage(userRowData?.profileImage)
        setIsActive(false);
    };

    useEffect(() => {
        if (userRowData !== undefined && userRowData !== null && addEditUser === true) {
            getCustomerById();
        }
    }, [userRowData, addEditUser]);

    //File Upload Function
    const handleFileUpload = (event) => {
        const files = Array.from(event.files);

        const updatedFiles = files.map((file) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setSelectedFiles((prevFiles) => [{ file_extension: file.type, base64: base64String, name: file.name, objectURL: file.objectURL || null }]);
            };
            reader.readAsDataURL(file);

            return file;
        });
    };

    const removeFile = () => {
        setSelectedFiles([]);
    };

    const removeFile2 = () => {
        setSelectedFiles([]);
        setEditableImage('');
        // userRowData.profileImage = "";
    };

    //Disable Form while Viewing
    const formStyle = {
        pointerEvents: viewDisable ? "none" : "auto",
        opacity: viewDisable ? 0.7 : 1,
    };

    return (
        <>
            <LoadingOverlay
                active={isActive}
                spinner
                text="Loading your content..."
                styles={{
                    overlay: (base) => ({
                        ...base,
                        color: "gray",
                        background: "#798403",
                        width: "107.9%",
                        height: "125%",
                        top: "-27px",
                        left: "-35px",
                    }),
                }}
            >
                <form onSubmit={formik.handleSubmit} style={formStyle}>
                    <div className="p-fluid formgrid grid pl-2 pr-2">
                        <div className="col-12 fields-display justify-content-evenly">
                            <div className="col-12 flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> English Name </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText
                                    autoComplete="off"
                                    keyfilter={/^[a-zA-Z\s]*$/}
                                    onPaste={handlePaste}
                                    maxLength={20}
                                    placeholder="Enter English name"
                                    className="p-inputtext-sm mt-2"
                                    id="englishName"
                                    name="englishName"
                                    value={formik.values.englishName}
                                    onChange={(e) => {
                                        formik.setFieldValue("englishName", e.target.value.trim());
                                    }}
                                    onDragOver={(e) => e.preventDefault()} // Disable drag-over
                                    onDrop={(e) => e.preventDefault()} // Disable drop
                                    type="text"
                                />
                                {getFormErrorMessage("englishName")}
                            </div>

                            <div className="col-12 custom-margin flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Urdu Name </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText
                                    placeholder="اردو میں نام درج کریں"
                                    className="p-inputtext-sm urdu mt-2"
                                    id="urduName"
                                    name="urduName"
                                    value={formik.values.urduName}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^[\u0600-\u06FF\s]*$/.test(value)) {
                                            formik.setFieldValue("urduName", value);
                                        }
                                    }}
                                    type="text"
                                />
                                {getFormErrorMessage("urduName")}
                            </div>
                        </div>

                        <div className="col-12 fields-display justify-content-evenly">
                            <div className="col-12 flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Email </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText placeholder="Enter Email" className="p-inputtext-sm mt-2" id="email" name="email" value={formik.values.email} onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("email")}
                            </div>

                            <div className="col-12 flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Contact No. </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText
                                    mask="03999999999"
                                    placeholder="03XXXXXXXXX"
                                    keyfilter={/^[0-9.!@#$%^&*+]+$/}
                                    maxLength={11}
                                    className={`p-inputtext-sm mt-2 ${formik.touched.phone && formik.errors.phone ? "p-invalid" : ""}`}
                                    id="phone"
                                    name="phone"
                                    value={formik.values.phone || ""}
                                    onChange={formik.handleChange}
                                />
                                {/* {getFormErrorMessage("phone")} */}
                                {formik.touched.phone && formik.errors.phone && <small className="p-error">{formik.errors.phone}</small>}
                            </div>
                        </div>
                        <div className="col-12 fields-display justify-content-evenly">
                            {/* <div className="col-12 flex flex-column md:col-5">
                                <div className='flex flex-row'>
                                    <label> Pin </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText placeholder='Enter pin' className="p-inputtext-sm mt-2" id="pin" name="pin" value={formik.values.pin} onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("pin")}
                            </div> */}

                            <div className="col-12 flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label>CNIC</label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText
                                    mask="9999999999999"
                                    placeholder="XXXXXXXXXXXXX"
                                    keyfilter={/^[0-9.!@#$%^&*+]+$/}
                                    maxLength={13}
                                    className={`p-inputtext-sm mt-2 ${formik.touched.cnic && formik.errors.cnic ? "p-invalid" : ""}`}
                                    id="cnic"
                                    name="cnic"
                                    value={formik.values.cnic}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    type="text"
                                />
                                {formik.touched.cnic && formik.errors.cnic && <small className="p-error">{formik.errors.cnic}</small>}
                            </div>

                            <div className="col-12 flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label>{addEditUser ? "Image" : "Upload Image"}</label>
                                    <span className="Label__Required">*</span>
                                </div>
                                {(!addEditUser && selectedFiles?.length !== 0) || (addEditUser && (editableImage !== "" || selectedFiles?.length !== 0)) ? null : (
                                    <FileUpload
                                        disabled={(!addEditUser && selectedFiles?.length !== 0) || (addEditUser && editableImage !== "") || selectedFiles?.length !== 0 ? true : false}
                                        auto
                                        mode="basic"
                                        chooseLabel="Choose File"
                                        className="mt-2"
                                        onSelect={handleFileUpload}
                                        accept="image/*"
                                    />
                                )}

                                {!addEditUser ? (
                                    <div className="flex flex-row justify-content-between mt-2">
                                        {selectedFiles?.length ? (
                                            <>
                                                <span style={{ fontSize: "14px", fontStyle: "italic" }}>
                                                    {" "}
                                                    <img className="img-styling" src={selectedFiles[0]?.base64} />{" "}
                                                </span>
                                                <Button tooltip="Remove" tooltipOptions={{ position: "top" }} type="button" icon="pi pi-times" className="ml-2" style={{ backgroundColor: "#f8f4ee", border: "gray", marginTop: "-20px", color: "gray" }} onClick={() => removeFile()} />
                                            </>
                                        ) : null}
                                    </div>
                                ) : (
                                    <div className="flex flex-row justify-content-between mt-2">
                                        {editableImage ? (
                                            <>
                                                <span style={{ fontSize: "14px", fontStyle: "italic" }}>
                                                    <img className="img-styling" src={`${baseURL}/${editableImage}`} alt="Profile Image" />{" "}
                                                </span>
                                                <Button tooltip="Remove" tooltipOptions={{ position: "top" }} type="button" icon="pi pi-times" className="ml-2" style={{ backgroundColor: "#f8f4ee", border: "gray", marginTop: "-20px", color: "gray" }} onClick={() => removeFile2()} />
                                            </>
                                        ) : selectedFiles?.length ? (
                                            <>
                                                <span style={{ fontSize: "14px", fontStyle: "italic" }}>
                                                    <img className="img-styling" src={selectedFiles[0]?.base64} alt="Profile Image" />{" "}
                                                </span>
                                                <Button tooltip="Remove" tooltipOptions={{ position: "top" }} type="button" icon="pi pi-times" className="ml-2" style={{ backgroundColor: "#f8f4ee", border: "gray", marginTop: "-20px", color: "gray" }} onClick={() => removeFile()} />
                                            </>
                                        ) : null}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="col-12 fields-display justify-content-evenly">
                            <div className="col-12 flex flex-column md:col-5">
                                {addEditUser ? (
                                    <>
                                        <div className="flex flex-row">
                                            <label> Status </label>
                                            <span className="Label__Required">*</span>
                                        </div>
                                        <Dropdown
                                            placeholder="--Select Status--"
                                            options={[
                                                { name: "Active", value: true },
                                                { name: "Inactive", value: false },
                                            ]}
                                            optionLabel="name"
                                            optionValue="value"
                                            className="p-inputtext-sm mt-2"
                                            id="status"
                                            name="status"
                                            value={formik.values.status}
                                            onChange={formik.handleChange}
                                        />
                                        {getFormErrorMessage("status")}
                                    </>
                                ) : null}
                            </div>
                            <div className="col-12 flex flex-column md:col-5"></div>
                        </div>

                        {!viewDisable ? (
                            <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                                <Button className="Cancel-Button mr-1 mb-2" label="Cancel" type="button" onClick={onHide} />
                                <Button
                                    disabled={(!addEditUser && selectedFiles?.length !== 0) || (addEditUser && editableImage !== "") || selectedFiles?.length !== 0 ? false : true}
                                    className="Save-Button ml-1 mb-2"
                                    label={addEditUser ? "Update" : "Add"}
                                    loading={saveBtnLoading}
                                    type="submit"
                                />
                            </div>
                        ) : null}
                    </div>
                </form>
            </LoadingOverlay>
        </>
    );
};

export default AddEditCustomer;
