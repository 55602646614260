import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingOverlay from "react-loading-overlay";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";
import { useHistory } from "react-router-dom";
import { handlePatchRequest } from "../../services/PatchTemplate";
import { RadioButton } from "primereact/radiobutton";

const AddEditUsers = ({ onHide, getUserList, addEditUser, userRowData, viewDisable }) => {
    const [saveBtnLoading, setSaveBtnLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();

    const isSequential = (value) => {
        // Skip the first two digits (03)
        const digits = value.slice(2).split("").map(Number);

        // Check if all digits are the same (e.g., "3333333333")
        const allSame = digits.every((digit) => digit === digits[0]);
        if (allSame) return true;

        // Check for strictly increasing sequence (e.g., "123456789")
        const isIncreasing = digits.every((digit, index) => {
            if (index === 0) return true; // Skip the first digit
            return digit === digits[index - 1] + 1;
        });
        if (isIncreasing) return true;

        // Check for strictly decreasing sequence (e.g., "987654321")
        const isDecreasing = digits.every((digit, index) => {
            if (index === 0) return true; // Skip the first digit
            return digit === digits[index - 1] - 1;
        });
        if (isDecreasing) return true;

        // Check for four consecutive identical digits
        for (let i = 0; i < digits.length - 3; i++) {
            if (digits[i] === digits[i + 1] && digits[i] === digits[i + 2] && digits[i] === digits[i + 3]) {
                return true; // Return true if four consecutive identical digits are found
            }
        }

        return false;
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .trim() // Remove leading and trailing spaces
            .required("This field is required.") // Field must not be empty
            .matches(/^(?!\s*$).+/, "This field cannot be empty or just spaces"), // Prevent only spaces
        lastName: Yup.string()
            .trim()
            .required("This field is required.")
            .matches(/^(?!\s*$).+/, "This field cannot be empty or just spaces"),
        // email: Yup.mixed().required("This field is required."),
        email: Yup.string()
            .email("Invalid email address") // Default email format validation
            .required("Email is required") // Email is required
            .max(30, "Email should not exceed 30 characters") // Max length validation
            .matches(
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[cC][oO][mM]$/, // Ensures email ends with .com
                "Email must end with .com" // Custom error message for .com requirement
            ),
        phone: Yup.string()
            .matches(/^03\d{9}$/, "Phone number must start with 03 and be 11 digits") // Matches valid phone format
            .test("is-not-sequential", "Invalid phone number (sequential numbers are not allowed)", (value) => {
                if (!value) return false; // Field is empty
                return !isSequential(value); // Check sequential validation
            })
            .required("This field is required."),

        role: Yup.mixed().required("This field is required."),
        password: addEditUser
            ? null
            : Yup.string()
                  .oneOf([Yup.ref("reEnterPassword"), null])
                  .required("Please Enter your Password"),
        reEnterPassword: addEditUser
            ? null
            : Yup.string()
                  .oneOf([Yup.ref("password"), null], "Passwords must match")
                  .required("Please Confirm your Password"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            role: "",
            password: "",
            reEnterPassword: "",
            status: true,
        },

        onSubmit: async (data) => {
            if (addEditUser === true) {
                let obj = {
                    userId: userRowData?.id,
                    firstName: formik.values.firstName,
                    lastName: formik.values.lastName,
                    email: formik.values.email,
                    phone: formik.values.phone,
                    isActive: formik.values.status,
                    role: formik.values.role,
                };

                setSaveBtnLoading(true);
                const response = await dispatch(handlePatchRequest(obj, `/api/v1/users/update`, false, true));
                console.log("response", response);
                if (response?.status === 200) {
                    getUserList();
                    setSaveBtnLoading(false);
                    onHide();
                } else {
                    setSaveBtnLoading(false);
                }
            } else {
                setSaveBtnLoading(true);

                let obj = {
                    firstName: formik.values.firstName,
                    lastName: formik.values.lastName,
                    email: formik.values.email,
                    phone: formik.values.phone,
                    password: formik.values.password,
                    role: formik.values.role,
                };

                const response = await dispatch(handlePostRequest(obj, "/api/v1/auth/SignUp", true, true));
                console.log("response", response);
                if (response?.status == 200 || response?.status == 201) {
                    getUserList();
                    setSaveBtnLoading(false);
                    onHide();
                } else {
                    setSaveBtnLoading(false);
                }
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    //Get User By Id
    const getUserById = async () => {
        setIsActive(true);

        formik.setFieldValue("firstName", userRowData?.firstName);
        formik.setFieldValue("lastName", userRowData?.lastName);
        formik.setFieldValue("email", userRowData?.email);
        formik.setFieldValue("phone", userRowData?.phone);
        formik.setFieldValue("role", userRowData?.role);
        formik.setFieldValue("status", userRowData?.isActive);

        setIsActive(false);
    };

    useEffect(() => {
        if (userRowData !== undefined && userRowData !== null && addEditUser === true) {
            getUserById();
        }
    }, [userRowData, addEditUser]);

    const roleOptions = [{ name: "Admin" }, { name: "User" }];

    //Disable Form while Viewing
    const formStyle = {
        pointerEvents: viewDisable ? "none" : "auto",
        opacity: viewDisable ? 0.7 : 1,
    };

    const handlePaste = (e) => {
        e.preventDefault(); // Prevents pasting
        alert("Pasting is not allowed in this field.");
    };

    return (
        <>
            <LoadingOverlay
                active={isActive}
                spinner
                text="Loading your content..."
                styles={{
                    overlay: (base) => ({
                        ...base,
                        color: "gray",
                        background: "#798403",
                        width: "107.9%",
                        height: "125%",
                        top: "-27px",
                        left: "-35px",
                    }),
                }}
            >
                <form onSubmit={formik.handleSubmit} style={formStyle}>
                    <div className="p-fluid formgrid grid pl-2 pr-2">
                        <div className="col-12 fields-display justify-content-evenly">
                            <div className="col-12 flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> First Name </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText
                                    keyfilter={/^[a-zA-Z\s]*$/}
                                    autoComplete="off"
                                    onPaste={handlePaste}
                                    maxLength={20}
                                    placeholder="Enter first name"
                                    className="p-inputtext-sm mt-2"
                                    id="firstName"
                                    name="firstName"
                                    value={formik.values.firstName}
                                    onChange={(e) => {
                                        // Automatically remove spaces
                                        formik.setFieldValue("firstName", e.target.value.trim());
                                    }}
                                    type="text"
                                />
                                {getFormErrorMessage("firstName")}
                            </div>

                            <div className="col-12 flex custom-margin flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Last Name </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText
                                    keyfilter={/^[a-zA-Z\s]*$/}
                                    autoComplete="off"
                                    onPaste={handlePaste}
                                    maxLength={20}
                                    placeholder="Enter last name"
                                    className="p-inputtext-sm mt-2"
                                    id="lastName"
                                    name="lastName"
                                    value={formik.values.lastName}
                                    onChange={(e) => {
                                        // Automatically remove spaces
                                        formik.setFieldValue("lastName", e.target.value.trim());
                                    }}
                                    type="text"
                                />
                                {getFormErrorMessage("lastName")}
                            </div>
                        </div>

                        <div className="col-12 fields-display justify-content-evenly">
                            <div className="col-12 flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Role </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <Dropdown placeholder="--Select Role--" id="role" name="role" options={roleOptions} optionLabel="name" optionValue="name" value={formik.values.role} onChange={formik.handleChange} className="p-inputtext-sm mt-2"></Dropdown>
                                {getFormErrorMessage("role")}
                            </div>

                            <div className="col-12 flex custom-margin flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Contact No. </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText
                                    maxLength={11}
                                    keyfilter={/^[0-9.!@#$%^&*+]+$/}
                                    mask="03999999999"
                                    placeholder="03XXXXXXXXX"
                                    className={`p-inputtext-sm mt-2 ${formik.touched.phone && formik.errors.phone ? "p-invalid" : ""}`}
                                    id="phone"
                                    name="phone"
                                    value={formik.values.phone || ""}
                                    onChange={formik.handleChange}
                                />
                                {/* {getFormErrorMessage("phone")} */}
                                {formik.touched.phone && formik.errors.phone && <small className="p-error">{formik.errors.phone}</small>}
                            </div>
                        </div>

                        <div className="col-12 fields-display justify-content-evenly">
                            <div className="col-12 flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Email </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText maxLength={30} placeholder="Enter email" className="p-inputtext-sm mt-2" id="email" name="email" value={formik.values.email} onChange={formik.handleChange} type="email" />
                                {getFormErrorMessage("email")}
                            </div>

                            <div className="col-12 custom-margin flex flex-column md:col-5">
                                {addEditUser ? (
                                    <>
                                        <div className="custom-margin flex flex-row">
                                            <label> Status </label>
                                            <span className="Label__Required">*</span>
                                        </div>

                                        <div className="flex flex-row mt-3">
                                            <RadioButton in putId="active" name="status" value={true} onChange={formik.handleChange} checked={formik.values.status === true} />
                                            <label htmlFor="active" className="ml-2">
                                                Active
                                            </label>
                                            <RadioButton className="ml-8" inputId="inactive" name="status" value={false} onChange={formik.handleChange} checked={formik.values.status === false} />
                                            <label htmlFor="inactive" className="ml-2">
                                                InActive
                                            </label>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="flex flex-row">
                                            <label> Password </label>
                                            <span className="Label__Required">*</span>
                                        </div>
                                        <Password id="password" maxLength={20} placeholder="Enter password" name="password" value={formik.values.password} onChange={formik.handleChange} toggleMask className="p-inputtext-sm mt-2" />
                                        {getFormErrorMessage("password")}
                                    </>
                                )}
                            </div>
                        </div>

                        {!addEditUser ? (
                            <div className="col-12 fields-display justify-content-evenly">
                                <div className="col-12 flex flex-column md:col-5">
                                    <div className="flex flex-row">
                                        <label> Re-enter Password </label>
                                        <span className="Label__Required">*</span>
                                    </div>
                                    <Password id="reEnterPassword" maxLength={20} placeholder="Enter Re-enter password" name="reEnterPassword" value={formik.values.reEnterPassword} onChange={formik.handleChange} toggleMask className="p-inputtext-sm mt-2" autoComplete="off" />
                                    {getFormErrorMessage("reEnterPassword")}
                                </div>

                                <div className="col-12 flex flex-column md:col-5"></div>
                            </div>
                        ) : null}

                        {!viewDisable ? (
                            <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                                <Button className="Cancel-Button mr-1 mb-2" label="Cancel" type="button" onClick={onHide} />
                                <Button className="Save-Button ml-1 mb-2" label={addEditUser ? "Update" : "Add"} loading={saveBtnLoading} type="submit" />
                            </div>
                        ) : null}
                    </div>
                </form>
            </LoadingOverlay>
        </>
    );
};

export default AddEditUsers;
