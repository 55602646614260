//Local Url :
// let baseURL = "http://192.168.10.112:3006";

//Dev Url:
// let baseURL = "http://20.212.227.60:3078"

//QA Url:
let baseURL = process.env.REACT_APP_BASE_URL_BACKEND;

export { baseURL };
