import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoadingOverlay from "react-loading-overlay";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";
import { useHistory } from "react-router-dom";
import { FileUpload } from "primereact/fileupload";
import { baseURL } from "../../Config";
import { handlePatchRequest } from "../../services/PatchTemplate";
import { RadioButton } from "primereact/radiobutton";
import { Image } from "primereact/image";
import { Toast } from "primereact/toast";

const AddEditEquipmentManagement = ({ onHide, getEquipmentList, addEditUser, userRowData, viewDisable }) => {
    console.log('userRowData', userRowData)
    const [saveBtnLoading, setSaveBtnLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [allEquipmentTypes, setAllEquipmentTypes] = useState([]);
    const [allFuelTypes, setAllFuelTypes] = useState([]);
    const [editableImage, setEditableImage] = useState([]);
    const [selectedFarmerId, setSelectedFarmerId] = useState(userRowData?.equipmentFarmerId || null); // Initialize with existing farmer ID if available
    const toast = useRef(null);

    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        englishName: Yup.mixed().required("This field is required."),
        urduName: Yup.mixed().required("This field is required."),
        model: Yup.mixed().required("This field is required."),
        fuelType: Yup.mixed().required("This field is required."),
        perHourChargesType: Yup.mixed().required("This field is required."),
        perDayChargesType: Yup.mixed().required("This field is required."),
        equipmentAddress: Yup.mixed().required("This field is required."),
        latitude: Yup.mixed().required("This field is required."),
        longitude: Yup.mixed().required("This field is required."),
        farmer: Yup.mixed().required("This field is required."),
        equipmentCategory: Yup.mixed().required("This field is required."),
        perDayCharges: Yup.mixed().required("This field is required."),
        equipmentPerHourCharges: Yup.mixed().required("This field is required."),
        isActive: Yup.mixed().required("This field is required."),
        comments: Yup.mixed().required("This field is required."),
        equipmentRating: Yup.mixed().required("This field is required."),
    });

    const formik = useFormik({
        // validationSchema: validationSchema,
        initialValues: {
            englishName: "",
            urduName: "",
            model: "",
            fuelType: "",
            perHourChargesType: 2,
            perDayChargesType: 1,
            equipmentAddress: "",
            latitude: "",
            longitude: "",
            farmer: "",
            farmerUrduName: "",
            equipmentCategory: "",
            perDayCharges: "",
            equipmentPerHourCharges: "",
            isActive: userRowData?.isActive === 1 ? true : false, // Set initial value based on API response
            comments: "",
            equipmentRating: "",
        },

        onSubmit: async (data) => {
            const newImagesBase64 = selectedFiles.map(file => file.base64);
            const existingImages = editableImage.filter(image => !selectedFiles.some(file => file.name === image.name));

            if (addEditUser === true) {
                let obj = {
                    equipmentId: userRowData?.equipmentId,
                    englishName: formik.values.englishName,
                    urduName: formik.values.urduName,
                    model: formik.values.model,
                    fuelType: formik.values.fuelType,
                    perHourChargesType: formik.values.perHourChargesType, // Send 1 for Hourly, 2 for other
                    perDayChargesType: formik.values.perDayChargesType, // Send 1 for Daily, 2 for other
                    latitude: formik.values.latitude,
                    longitude: formik.values.longitude,
                    // farmer: formik.values.farmer,
                    // farmer: selectedFarmerId, // Use Farmer ID instead of name
                    equipmentAddress: formik.values.equipmentAddress,
                    equipmentCategory: formik.values.equipmentCategory,
                    perDayCharges: formik.values.perDayCharges,
                    perHourCharges: formik.values.equipmentPerHourCharges,
                    images: [...existingImages, ...newImagesBase64],
                    isActive: formik.values.isActive,
                };

                setSaveBtnLoading(true);
                const response = await dispatch(handlePatchRequest(obj, `/api/v1/equipment/update`, false, true));
                if (response?.status === 200) {
                    getEquipmentList();
                    setSaveBtnLoading(false);
                    onHide();
                } else {
                    setSaveBtnLoading(false);
                }
            } else {
                setSaveBtnLoading(true);
                let obj = {
                    equipmentCategory: formik.values.equipmentCategory,
                    fuelType: formik.values.fuelType,
                    englishName: formik.values.Name,
                    model: formik.values.model,
                    images: newImagesBase64,
                };

                const response = await dispatch(handlePostRequest(obj, "/api/v1/equipment/create", true, true));
                if (response) {
                    getEquipmentList();
                    setSaveBtnLoading(false);
                    onHide();
                }
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    //Get Equipment By Id
    const getEquipmentById = async () => {
        setIsActive(true);
        formik.setFieldValue("englishName", userRowData?.equipmentEnglishName);
        formik.setFieldValue("urduName", userRowData?.equipmentUrduName);
        formik.setFieldValue("equipmentAddress", userRowData?.equipmentAddress);
        formik.setFieldValue("equipmentCategory", userRowData?.equipmentCategoryId);
        formik.setFieldValue("farmer", userRowData?.equipmentFarmerName);
        formik.setFieldValue("farmerUrduName", userRowData?.equipmentFarmerUrduName);
        formik.setFieldValue("equipmentCreatedDate", userRowData?.equipmentCreatedDate);
        formik.setFieldValue("fuelType", userRowData?.equipmentFuelTypeId);
        formik.setFieldValue("isActive", userRowData?.isActive);
        formik.setFieldValue("latitude", userRowData?.equipmentLatitude);
        formik.setFieldValue("longitude", userRowData?.equipmentLongitude);
        formik.setFieldValue("model", userRowData?.equipmentModel);
        formik.setFieldValue("perHourChargesType", userRowData?.equipmentPerHourChargesTypeId);
        formik.setFieldValue("perDayChargesType", userRowData?.equipmentPerDayChargesTypeId);
        formik.setFieldValue("perDayCharges", userRowData?.equipmentPerDayCharges);
        formik.setFieldValue("perHourCharges", userRowData?.equipmentPerHourCharges);
        setEditableImage(userRowData?.equipmentImages)
        setIsActive(false);
    };

    useEffect(() => {
        if (userRowData !== undefined && userRowData !== null && addEditUser === true) {
            getEquipmentById();
        }
    }, [userRowData, addEditUser]);

    //File Upload Function
    const handleFileUpload = (event) => {
        const files = Array.from(event.files);
        const MAX_SIZE = 5 * 1024 * 1024; // Maximum file size (5 MB)
        const ALLOWED_TYPES = ["image/jpeg", "image/png", "image/jpg"]; // Allowed file types

        files.forEach((file) => {
            // Validation for file size and type
            if (file.size > MAX_SIZE || !ALLOWED_TYPES.includes(file.type)) {
                toast.current.show({ severity: 'error', summary: 'Invalid File', detail: 'Please upload a JPEG/PNG file under 5MB.', life: 3000 });
                return;
            }

            // Process file to Base64
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setSelectedFiles((prevFiles) => [
                    ...prevFiles,
                    {
                        file_extension: file.type,
                        base64: base64String,
                        name: file.name,
                        objectURL: file.objectURL || null,
                    },
                ]);
            };
            reader.readAsDataURL(file);
        });
    };

    const removeFile = (index) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const removeFile2 = () => {
        setSelectedFiles([]);
        setEditableImage([]);
    };

    const removeSpecificImage = (index) => {
        setEditableImage((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    const imageTemplate = (image, index, isEditable) => {
        return (
            <div key={index} style={{ position: "relative", display: "inline-block", marginRight: "10px" }}>
                <Image src={isEditable ? `${baseURL}/${image}` : image.base64} zoomSrc={isEditable ? `${baseURL}/${image}` : image.base64} alt="Image" preview />
                <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-sm" style={{ position: "absolute", top: "2px", right: "2px", width: "20px", height: "20px", padding: "0" }} onClick={() => (isEditable ? removeSpecificImage(index) : removeFile(index))} />
            </div>
        );
    };

    //Disable Form while Viewing
    const formStyle = {
        pointerEvents: viewDisable ? "none" : "auto",
        opacity: viewDisable ? 0.7 : 1,
    };

    //Get All Equipment
    const getAllEquipmentTypes = async () => {
        setIsActive(true);

        const response = await dispatch(handleGetRequest("/api/v1/equipmetCategory/getAll", true));
        if (response) {
            setAllEquipmentTypes(response?.data);
        }
        setIsActive(false);
    };

    //Get All Fuel List
    const getAllFuelTypes = async () => {
        setIsActive(true);

        const response = await dispatch(handleGetRequest("/api/v1/fuelType/getAll", true));
        if (response) {
            setAllFuelTypes(response?.data);
        }
        setIsActive(false);
    };

    useEffect(() => {
        getAllEquipmentTypes();
        getAllFuelTypes();
    }, []);

    const handlePaste = (e) => {
        e.preventDefault(); // Prevents pasting
        alert("Pasting is not allowed in this field.");
    };

    console.log('editableImage', editableImage)
    console.log('formik.values.farmer', formik.values.farmer)

    return (
        <>
            <Toast ref={toast} />
            <LoadingOverlay
                active={isActive}
                spinner
                // text="Loading your content..."
                styles={{
                    overlay: (base) => ({
                        ...base,
                        color: "gray",
                        background: "none",
                        width: "107.9%",
                        height: "125%",
                        top: "-27px",
                        left: "-35px",
                    }),
                }}
            >
                <form onSubmit={formik.handleSubmit} style={formStyle}>
                    <div className="p-fluid formgrid grid pl-2 pr-2">
                        <div className="col-12 fields-display justify-content-evenly">
                            <div className="col-12 flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Equipmemt (English Name) </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText
                                    placeholder="Enter english name"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^[a-zA-Z\s]*$/.test(value)) {
                                            formik.setFieldValue("englishName", value);
                                        }
                                    }}
                                    autoComplete="off"
                                    onPaste={handlePaste}
                                    className="p-inputtext-sm mt-2"
                                    id="englishName"
                                    name="englishName"
                                    value={formik.values.englishName}
                                    type="text"
                                />
                                {getFormErrorMessage("englishName")}
                            </div>

                            <div className="col-12 custom-margin flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Equipmemt (Urdu Name) </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText
                                    maxLength={20}
                                    placeholder="Enter urdu name"
                                    className="p-inputtext-sm mt-2"
                                    id="urduName"
                                    name="urduName"
                                    value={formik.values.urduName}
                                    type="text"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^[\u0600-\u06FF\s]*$/.test(value)) {
                                            formik.setFieldValue("urduName", value);
                                        }
                                    }}
                                />
                                {getFormErrorMessage("urduName")}
                            </div>
                        </div>

                        <div className="col-12 fields-display justify-content-evenly">
                            <div className="col-12 custom-margin flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Equipmemt Model </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText maxLength={20} placeholder="Enter model" className="p-inputtext-sm mt-2" id="model" name="model" value={formik.values.model} onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("model")}
                            </div>

                            <div className="col-12 flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Fuel Type </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <Dropdown placeholder="--Select--" options={allFuelTypes} optionLabel="Name" optionValue="id" className="p-inputtext-sm mt-2" id="fuelType" name="fuelType" value={formik.values.fuelType} onChange={formik.handleChange} />
                                {getFormErrorMessage("fuelType")}
                            </div>
                        </div>

                        <div className="col-12 fields-display justify-content-evenly">
                            {/* Per Hour Charges Type */}
                            <div className="col-12 flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Per Hour Charges Type </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <div className="flex flex-column mt-2">
                                    <RadioButton inputId="perHourChargesType" name="perHourChargesType" value="Hourly" checked={formik.values.perHourChargesType == 2} onChange={formik.handleChange} />
                                    <label htmlFor="perHourChargesType1">Hourly</label>
                                </div>
                                {getFormErrorMessage("perHourChargesType")}
                            </div>

                            {/* Per Day Charges Type */}
                            <div className="col-12 custom-margin flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Per Day Charges Type </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <div className="flex flex-column mt-2">
                                    <RadioButton inputId="perDayChargesType1" name="perDayChargesType" value="Daily" checked={formik.values.perDayChargesType == 1} onChange={formik.handleChange} />
                                    <label htmlFor="perDayChargesType1">Daily</label>
                                </div>
                                {getFormErrorMessage("perDayChargesType")}
                            </div>
                        </div>

                        <div className="col-12 fields-display justify-content-evenly">
                            <div className="col-12 flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Equipment Address </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText placeholder="Enter Equipment Address" className="p-inputtext-sm mt-2" id="equipmentAddress" name="equipmentAddress" value={formik.values.equipmentAddress} onChange={formik.handleChange} />
                                {getFormErrorMessage("equipmentAddress")}
                            </div>
                            <div className="col-12 custom-margin flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Status </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <Dropdown
                                    placeholder="--Select Status--"
                                    options={[
                                        { name: "Active", value: 1 },
                                        { name: "Inactive", value: 2 },
                                    ]}
                                    optionLabel="name"
                                    optionValue="value"
                                    className="p-inputtext-sm mt-2"
                                    id="isActive"
                                    name="isActive"
                                    value={formik.values.isActive}
                                    onChange={formik.handleChange}
                                />
                                {getFormErrorMessage("isActive")}
                            </div>
                        </div>

                        <div className="col-12 fields-display justify-content-evenly">
                            <div className="col-12 flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> longitude </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText maxLength={20} placeholder="Enter Longitude" className="p-inputtext-sm mt-2" id="longitude" name="longitude" value={formik.values.longitude} onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("longitude")}
                            </div>

                            <div className="col-12 custom-margin flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Farmer </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText
                                    maxLength={20}
                                    placeholder="Enter Farmer Name"
                                    className="p-inputtext-sm mt-2"
                                    id="farmer"
                                    name="farmer"
                                    value={formik.values.farmer}
                                    // onChange={handleFarmerId}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        const farmerName = e.target.value;
                                        // Example logic to update ID based on farmer name
                                        const matchedFarmer = userRowData?.equipmentFarmerName === farmerName;
                                        setSelectedFarmerId(matchedFarmer ? matchedFarmer.equipmentId : null);
                                    }}
                                    type="text"
                                />
                                {getFormErrorMessage("farmer")}
                            </div>
                        </div>

                        <div className="col-12 fields-display justify-content-evenly">
                            <div className="col-12 flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Farmer (Urdu Name) </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText maxLength={20} placeholder="Enter Farmer Urdu Name" className="p-inputtext-sm mt-2" id="farmerUrduName" name="farmerUrduName" value={formik.values.farmerUrduName} onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("farmerUrduName")}
                            </div>
                            <div className="col-12 custom-margin flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Equipment Created Date </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText placeholder="Enter Equipment Created Date" className="p-inputtext-sm mt-2" id="equipmentCreatedDate" name="equipmentCreatedDate" value={formik.values.equipmentCreatedDate} onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("equipmentCreatedDate")}
                            </div>
                        </div>

                        <div className="col-12 fields-display justify-content-evenly">
                            <div className="col-12 flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Equipment Category </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <Dropdown placeholder="--Select--" options={allEquipmentTypes} optionLabel="categoryName" optionValue="id" className="p-inputtext-sm mt-2" id="equipmentCategory" name="equipmentCategory" value={formik.values.equipmentCategory} onChange={formik.handleChange} />
                                {getFormErrorMessage("equipmentCategory")}
                            </div>

                            <div className="col-12 custom-margin flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Per Day Charges </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText placeholder="Enter Per Day Charges" className="p-inputtext-sm mt-2" id="perDayCharges" name="perDayCharges" value={formik.values.perDayCharges} onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("perDayCharges")}
                            </div>
                        </div>

                        <div className="col-12 fields-display justify-content-evenly">
                            <div className="col-12 flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> Per Hour Charges </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText placeholder="Enter Per Hour Charges" className="p-inputtext-sm mt-2" id="equipmentPerHourCharges" name="equipmentPerHourCharges" value={formik.values.equipmentPerHourCharges} onChange={formik.handleChange} />
                                {getFormErrorMessage("equipmentPerHourCharges")}
                            </div>

                            <div className="col-12 custom-margin flex flex-column md:col-5">
                                <div className="flex flex-row">
                                    <label> latitude </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <InputText placeholder="Enter latitude" className="p-inputtext-sm mt-2" id="latitude" name="latitude" value={formik.values.latitude} onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("latitude")}
                            </div>
                        </div>

                        <div className="col-12 justify-content-evenly">
                            <div className="col-12 flex ml-8 flex-column md:col-5 mb-2">
                                <div className="flex flex-row">
                                    <label> Equipmemt Images </label>
                                </div>
                                {editableImage && editableImage?.length > 0 ? (
                                    editableImage.map((image, index) => (
                                        <div key={index} className="flex items-center mt-2">
                                            <span style={{ fontSize: "14px", fontStyle: "italic" }}>
                                                <img
                                                    className="img-styling"
                                                    src={`${baseURL}/${image?.url}`}
                                                // alt={Profile Image ${index + 1}}
                                                />
                                            </span>
                                            {/* <Button
                                                tooltip="Remove"
                                                tooltipOptions={{ position: "top" }}
                                                type="button"
                                                icon="pi pi-times"
                                                className="ml-2"
                                                style={{
                                                    backgroundColor: "#f8f4ee",
                                                    border: "gray",
                                                    marginTop: "-20px",
                                                    color: "gray",
                                                }}
                                                onClick={() => removeFile2(index)} // Pass index to identify which file to remove
                                            /> */}
                                        </div>
                                    ))
                                ) : selectedFiles?.length ? (
                                    <>
                                        <span style={{ fontSize: "14px", fontStyle: "italic" }}>
                                            <img
                                                className="img-styling"
                                                src={selectedFiles[0]?.base64}
                                                alt="Profile Image"
                                            />
                                        </span>
                                        {/* <Button
                                            tooltip="Remove"
                                            tooltipOptions={{ position: "top" }}
                                            type="button"
                                            icon="pi pi-times"
                                            className="ml-2"
                                            style={{
                                                backgroundColor: "#f8f4ee",
                                                border: "gray",
                                                marginTop: "-20px",
                                                color: "gray",
                                            }}
                                            onClick={() => removeFile()}
                                        /> */}
                                    </>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-12 fields-display justify-content-evenly">
                            <div className="col-12 flex flex-column md:col-8">
                                {/* <div className="flex flex-row">
                                    <label> Equipment Images </label>
                                    <span className="Label__Required">*</span>
                                </div>
                                <div className="flex flex-row mt-2">
                                    {Array.isArray(editableImage) && editableImage.map((image, index) => imageTemplate(image, index, true))}
                                    {selectedFiles.map((file, index) => imageTemplate(file, index, false))}
                                </div>
                                <FileUpload auto mode="basic" chooseLabel="Choose Files" className="mt-2" onSelect={handleFileUpload} multiple accept="image/*" />
                                <div className="flex flex-row justify-content-between mt-2">
                                    {editableImage.length > 0 && (
                                        <Button tooltip="Remove All" tooltipOptions={{ position: "top" }} type="button" icon="pi pi-times" className="ml-2" style={{ backgroundColor: "#f8f4ee", border: "gray", marginTop: "-20px", color: "gray" }} onClick={() => removeFile2()} />
                                    )}
                                    {selectedFiles.length > 0 && <Button tooltip="Remove" tooltipOptions={{ position: "top" }} type="button" icon="pi pi-times" className="ml-2" style={{ backgroundColor: "#f8f4ee", border: "gray", marginTop: "-20px", color: "gray" }} onClick={() => removeFile(0)} />}
                                </div> */}
                            </div>
                        </div>

                        {!viewDisable ? (
                            <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                                <Button className="Cancel-Button mr-1 mb-2" label="Cancel" type="button" onClick={onHide} />
                                <Button disabled={selectedFiles?.length !== 0 || editableImage !== "" ? false : true} className="Save-Button ml-1 mb-2" label={addEditUser ? "Update" : "Add"} loading={saveBtnLoading} type="submit" />
                            </div>
                        ) : null}
                    </div>
                </form>
            </LoadingOverlay>
        </>
    );
};

export default AddEditEquipmentManagement;
