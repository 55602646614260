import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import "../Login.css";
import portalLogo from "../../../assets/farmkar_assets/Png/logo-black.png";
import { handlePostRequest } from "../../../services/PostTemplate";

const ForgotPassword = () => {
    const [saveBtnLoading, setSaveBtnLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email("Invalid email address") // Default email format validation
            .required("Email is required") // Email is required
            .max(30, "Email should not exceed 30 characters") // Max length validation
            .matches(
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[cC][oO][mM]$/, // Ensures email ends with .com
                "Email must end with .com" // Custom error message for .com requirement
            ),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            email: "",
        },

        onSubmit: async (data) => {
            setSaveBtnLoading(true);

            const response = await dispatch(handlePostRequest(data, "/api/v1/users/sendOtp", false, true));
            if (response?.status === 200) {
                history.push({
                    pathname: `/otp`,
                    state: { email: formik.values.email },
                });
            } else {
                setSaveBtnLoading(false);
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="login-page-container splash-screen">
                <div className="left-section ml-6">
                    <div className="login2-box">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="login-headings">
                                <h2 style={{ fontWeight: "700" }}> Forgot Password </h2>
                                <p className="mb-4" style={{ color: "gray", fontWeight: "600", fontSize: "18px" }}>
                                    Please enter your valid email address for password reset and recovery.
                                </p>
                            </div>
                            <div className="mt-2">
                                <label htmlFor="email" className="text-xl font-medium mb-2">
                                    Email
                                </label>
                                <InputText maxLength={30} placeholder="Enter email" className="p-inputtext-sm mt-2" id="email" name="email" value={formik.values.email} onChange={formik.handleChange} type="email" />
                                {getFormErrorMessage("email")}

                                <div className="col-12 d-flex flex-row text-center mt-5">
                                    <Button className="Login-Button w-full" label="Send OTP" icon="pi pi-check" loading={saveBtnLoading} type="submit" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="right-section ">
                    <img src={portalLogo} className="logo-portal mr-6" />
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;
